import React from "react";
import { Avatar, useChatContext } from "stream-chat-react";

export default function TeamChannelPreview({ setActiveChannel,setIsCreating,setIsEditing,setToggleContainer,channel, type }) {
  const { channel: activeChannel, client } = useChatContext();

  const ChannelPreview = () => (
    <p className="channel-preview__item">
      # {channel?.data?.name || channel?.data?.id}
    </p>
  );

  const DirectPreview = () => {
    const members = Object.values(channel.state.members).filter(({ user }) => user.id !== client.userID);
    console.log(members[0])

    return (
      <div className="channel-preview__item single">
        <Avatar
          image={members[0].user.image}
          name={members[0]?.user?.fullname}
          size={24}
        />
        <p>{members[0]?.user?.fullname || members[0]?.user?.id}</p>
      </div>
    );
  };

  return (
    <div
      className={
        channel?.id === activeChannel?.id
          ? "channel-preview__wrapper__selected"
          : "channel-preview__wrapper"
      }
      onClick={() => {
        setIsCreating(false)
        setIsEditing(false)
        setActiveChannel(channel)
        if(setToggleContainer){
          setToggleContainer((prevState)=>!prevState)
        }
      }}
    >
      {type === "team" ? <ChannelPreview /> : <DirectPreview />}
    </div>
  );
}
